import React, { useEffect, useState } from 'react';
import {useParams, useNavigate} from "react-router-dom";
import {Typography, Button, IconButton} from '@mui/material';
import API from '../services/API';
import { DataGrid } from '@mui/x-data-grid';
import { Info } from '@mui/icons-material';

export default function Recherche(){
    const [concessions, setConcessions] = useState([]);
    const [concessionnaires, setConcessionnaires] = useState([]);
    const [defunts, setDefunts] = useState([]);

    let { input } = useParams();
    let navigate = useNavigate();

    const columnAction = [    
        {field: 'action', headerName: '', sortable: false, flex:1,
        renderCell: (params) => {
            const onClick = (e)=>{
                e.stopPropagation();
                let Concession_numero = params.row.hasOwnProperty("numero") ? params.row.numero : params.row.Concession_numero
                navigate("/concession/"+Concession_numero)
            }
            return <IconButton  onClick={onClick}><Info/></IconButton>
        },
}]

    useEffect(() => {
        API("GET","Concession/recherche",{},{input:input})
        .then(res=>setConcessions(res.json))
        API("GET","Concessionnaire/recherche",{},{input:input})
        .then(res=>setConcessionnaires(res.json))
        API("GET","Defunt/recherche",{},{input:input})
        .then(res=>setDefunts(res.json))
    }, [input]);

    return(
        <>
            <Typography variant="h5" sx={{textAlign:"center"}}>Résultats pour "{input}"</Typography>
            
            <Typography variant='h6' sx={{mt:4}}>Concessions</Typography>
            {concessions.length > 0 && 
            <DataGrid
                columns={[...columnsConcession, ...columnAction]}
                rows={concessions}
                autoHeight
                getRowId={(row) => row.numero}
                initialState={{
                    columns: {
                      columnVisibilityModel: {
                        type: false,
                        statut: false
                      },
                    },
                    pagination: { paginationModel: { pageSize: 25 } },
                }}
                hideFooterPagination={concessions.length<=25}
                pageSizeOptions={[25]}
            />}
            {concessions.length===0 && 
                <Typography sx={{ml:2}}>Aucun résultat</Typography>
            }

            <Typography variant='h6' sx={{mt:4}}>Concessionnaires</Typography>
            {concessionnaires.length>0 && 
            <DataGrid
                columns={[...columnsConcessionnaire, ...columnAction]}
                rows={concessionnaires}
                autoHeight
                initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                        date_naissance: false,
                        sexe:false
                      },
                    },
                    pagination: { paginationModel: { pageSize: 25 } },
                }}
                hideFooterPagination={concessionnaires.length<=25}
                pageSizeOptions={[25]}
            />}
            {concessionnaires.length===0 && 
                <Typography sx={{ml:2}}>Aucun résultat</Typography>
            }

            <Typography variant='h6' sx={{mt:4}}>Défunts</Typography>
            {defunts.length>0 && 
            <DataGrid
                columns={[...columnsDefunt, ...columnAction]}
                rows={defunts}
                autoHeight
                initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                        date_exhumation: false
                      },
                    },
                    pagination: { paginationModel: { pageSize: 25 } },
                }}
                hideFooterPagination={defunts.length<=25}
                pageSizeOptions={[25]}
            />}
            {defunts.length===0 && 
                <Typography sx={{ml:2}}>Aucun résultat</Typography>
            }
        </>
    )
}

const columnsConcession = [
    { field: 'numero', headerName: 'Numéro', flex:1},
    { field: 'Emplacement_nom', headerName: 'Emplacement', flex:1},
    { field: 'type', headerName: 'Type', flex:1, hide:true},
    { field: 'description', headerName: 'Description', flex:3},
    { field: 'statut', headerName: 'Statut', flex:1, hidden:true},
];

const columnsConcessionnaire = [
    { field: 'id', headerName: 'ID', flex:1},
    { field: 'Concession_numero', headerName: 'N° Concession', flex:1},
    { field: 'Emplacement_nom', headerName: 'Emplacement', flex:1},
    { field: 'prenom', headerName: 'Prénom', flex:1},
    { field: 'nom_naissance', headerName: 'Nom de naissance', flex:1},
    { field: 'nom_usage', headerName: "Nom d'usage", flex:1},
    { field: 'date_naissance', headerName: 'Date de naissance', flex:1},
    { field: 'sexe', headerName: 'Sexe', flex:1},
];

const columnsDefunt = [
    { field: 'id', headerName: 'ID', flex:1},
    { field: 'Concession_numero', headerName: 'N° Concession', flex:1},
    { field: 'Emplacement_nom', headerName: 'Emplacement', flex:1},
    { field: 'prenom', headerName: 'Prénom', flex:1},
    { field: 'nom_naissance', headerName: 'Nom de naissance', flex:1},
    { field: 'nom_usage', headerName: "Nom d'usage", flex:1},
    { field: 'date_naissance', headerName: 'Date de naissance', flex:1},
    { field: 'date_inhumation', headerName: "Date d'inhumation", flex:1},
    { field: 'date_exhumation', headerName: "Date d'exhumation", flex:1},
];