
import React, { useEffect, useState } from 'react';
import {useParams, useNavigate} from "react-router-dom";
import {Typography, Box, Button, ButtonGroup, Modal, IconButton, Link, Radio, RadioGroup, FormControlLabel} from '@mui/material';
import {EditOutlined, Add, FileCopyOutlined, FileUpload, Cookie, Cancel, Delete} from '@mui/icons-material';
import API from '../services/API';
import { getFormattedDate, prenomNOM } from '../services/Utils';
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import Input from '../components/Input';
import Dropdown from '../components/Dropdown';
import { DatePicker } from "@mui/x-date-pickers";
import DatePickerAbstract from '../components/DatePickerAbstract';

export default function Creation_Concession({}){
    let { Emplacement_nom } = useParams();

    const [emplacementsInnoccupes, setEmplacementsInnoccupes] = useState([]);
    const [reponses, setReponses] = useState({numero:undefined, Emplacement_nom:Emplacement_nom, en_cours:1, type:undefined, statut:"OK"});
    const [reponsesFondateur, setReponsesFondateur] = useState({fondateur:1, nom_naissance:undefined, prenom:undefined, date_naissance:undefined, sexe:"M"});
    const [reponsesRenouvellement, setReponsesRenouvellement] = useState({accorde_le:dayjs().format("YYYY-MM-DD"), duree:undefined, statut:"payé"});
    const [expirantLe, setExpirantLe] = useState(["",null]);
    const [answersOk, setAnswersOk] = useState(false);
    
    let navigate = useNavigate();

    useEffect(() => {
        if(Emplacement_nom===undefined){ 
            API("GET", "Emplacement",{},{occupation:true})
            .then(res=>{
                if(res.status ===200){
                    setEmplacementsInnoccupes(res.json.filter(e=>e.occupe===0).map(e=>e.nom))
                }
            })
        }else{
            handleChange("Emplacement_nom",Emplacement_nom)
        }
    }, []);

    const handleChange = (key, value)=>{
        setReponses(currVal => ({...currVal, [key]:value}))
    }
    const handleChangePhoto = (e)=>{
        const files = Array.from(e.target.files)
        setReponses(currVal => ({...currVal, file:files[0]}))
    }

    const handleChangeFondateur = (key,value)=>{
        setReponsesFondateur(currVal => ({...currVal, [key]:value}))
    }

    const handleChangeRenouvellement = (key, value)=>{
        setReponsesRenouvellement(currVal => ({...currVal, [key]:value}))
    }

    useEffect(() => {
        let duree = parseInt(reponsesRenouvellement.duree, 10)
        if(reponsesRenouvellement.accorde_le === undefined || isNaN(duree)){
            setExpirantLe(["-",null])
        }else{
            let tmp = dayjs(reponsesRenouvellement.accorde_le).add(duree,"year")
            setExpirantLe([tmp.format("DD-MM-YYYY"), tmp.format("YYYY-MM-DD")])
        }
    }, [reponsesRenouvellement]);

    useEffect(() => {
        setAnswersOk(Object.values({...reponses, ...reponsesFondateur, ...reponsesRenouvellement}).filter(a=>a===undefined).length===0)
        //console.log("reponsesLigneCommande",reponses)
    }, [reponses, reponsesFondateur, reponsesRenouvellement]);


    const handleCreate = ()=>{
        API("POST","Concession",reponses,{},"multipart/form-data")
        .then(res=>{
            if(res.status===201){
                let temp = reponsesFondateur
                temp.Concession_numero = reponses.numero
                temp.depuis_le = reponsesRenouvellement.accorde_le
                API("POST", "Concessionnaire",temp)
                .then(res2=>{
                    if(res.status===201){
                        temp = reponsesRenouvellement
                        temp.Concession_numero = reponses.numero
                        temp.Concessionnaire_id = res2.json.insertId
                        temp.expirant_le = expirantLe[1]
                        API("POST", "Renouvellement", temp)
                        .then(res3=>{
                            if(res3.status===201){
                                let url = "/tempo/"+encodeURIComponent("/concession/"+reponses.numero)
                                navigate(url)
                            }
                        })
                    }
                })
            }
        })


    }

    return(
        <Box sx={{mb:2}}>
            <Typography variant='h4' sx={{textAlign:"center"}}><strong>Création d'une concession</strong></Typography>

            <Box sx={styleBoxInput}>
                <Typography sx={styleInputLabel}>N° Emplacement : </Typography>
                {Emplacement_nom===undefined ?
                    <Dropdown required={true} onChange={(v)=>handleChange("Emplacement_nom",v)} options={emplacementsInnoccupes} style={{width:250}} otherProps={{size:"small"}}/>
                :
                    <Input disabled={true} required={true} defaultValue={Emplacement_nom} style={{width:250}} otherProps={{size:"small"}}/>
                }
            </Box>
            <Box sx={styleBoxInput}>
                <Typography sx={styleInputLabel}>N° Concession : </Typography>
                <Input required={true} onChange={(v)=>handleChange("numero",v)} style={{width:250}} otherProps={{size:"small"}}/>
            </Box>
            <Box sx={styleBoxInput}>
                <Typography sx={styleInputLabel}>Description : </Typography>
                <Input onChange={(v)=>handleChange("description",v)} style={{width:250}} otherProps={{size:"small",multiline:true}}/>
            </Box>
            <Box sx={styleBoxInput}>
                <Typography sx={styleInputLabel}>Photo : </Typography>
                <input type='file' onChange={handleChangePhoto} accept="image/png, image/jpeg, image/gif" />
            </Box>
            <Box sx={{border:"dashed 1px black", maxWidth:"500px", mx:"auto"}}>
                <Typography sx={{...styleInputLabel, textAlign:"left", ml:3}}>Fondateur : </Typography>
                <Box sx={{ml:0}}>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Prénom : </Typography>
                        <Input required={true} onChange={(v)=>handleChangeFondateur("prenom",v)} style={{width:250}} otherProps={{size:"small"}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Nom de naissance : </Typography>
                        <Input required={true} onChange={(v)=>handleChangeFondateur("nom_naissance",v)} style={{width:250}} otherProps={{size:"small"}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Nom d'usage : </Typography>
                        <Input onChange={(v)=>handleChangeFondateur("nom_usage",v)} style={{width:250}} otherProps={{size:"small"}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Date de naissance : </Typography>
                        {/*<DatePicker onChange={(v)=>handleChangeFondateur("date_naissance",v.format("YYYY-MM-DD"))} value={reponsesFondateur.date_naissance} format="DD-MM-YYYY" sx={{width:250, "& > .MuiInputBase-root > .MuiInputBase-input":{py:1}}} />*/}
                        <DatePickerAbstract required={true} onChange={(v)=>handleChangeFondateur("date_naissance",v)} format="DD-MM-YYYY" sx={{width:250, "& > .MuiInputBase-root > .MuiInputBase-input":{py:1}}} />
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Sexe : </Typography>
                        <RadioGroup value={reponsesFondateur?.sexe||"M"} onChange={(e)=>handleChangeFondateur("sexe",e.target.value)} sx={{flexDirection:"row", width:"250px", justifyContent:"center"}}>
                            <FormControlLabel value="M" control={<Radio />} label="M" />
                            <FormControlLabel value="F" control={<Radio />} label="F" />
                        </RadioGroup>
                    </Box>
                </Box>
            </Box>
            <Box sx={styleBoxInput}>
                <Typography sx={styleInputLabel}>Type : </Typography>
                <Dropdown options={["Familiale","Collective","Individuelle"]} onChange={(v)=>handleChange("type",v)} style={{width:250}} otherProps={{size:"small"}}/>
            </Box>
            <Box sx={styleBoxInput}>
                <Typography sx={styleInputLabel}>Sépulture pour : </Typography>
                <Input disabled={reponses.type!=="Collective"} onChange={(v)=>handleChange("sepulture_pour",v)} style={{width:250}} otherProps={{size:"small",multiline:true}}
                        defaultValue={reponses.type==="Collective"?"":(reponses.type==="Familiale"?"le fondateur et sa famille" : "le fondateur")                 
                    }
                />
            </Box>
            <Box sx={styleBoxInput}>
                <Typography sx={styleInputLabel}>Commentaires : </Typography>
                <Input onChange={(v)=>handleChange("commentaire",v)} style={{width:250}} otherProps={{size:"small",multiline:true}}/>
            </Box>
            

            <Box sx={styleBoxInput}>
                <Typography sx={styleInputLabel}>Accordé le : </Typography>
                <DatePickerAbstract required={true} onChange={(v)=>handleChangeRenouvellement("accorde_le",v)} defaultValue={"today"} format="DD-MM-YYYY" sx={{width:250, "& > .MuiInputBase-root > .MuiInputBase-input":{py:1}}} />
            </Box>
            <Box sx={styleBoxInput}>
                <Typography sx={styleInputLabel}>Durée : </Typography>
                <Dropdown required={true} options={["5 ans","10 ans","15 ans","30 ans","50 ans","perpétuel"]} onChange={(v)=>handleChangeRenouvellement("duree",v)} style={{width:250}} otherProps={{size:"small"}}/>
            </Box>
            <Box sx={styleBoxInput}>
                <Typography sx={styleInputLabel}>Expirant le : </Typography>
                <Input disabled={true} defaultValue={expirantLe[0]} style={{width:250}} otherProps={{size:"small"}} />
            </Box>


            <Button disabled={!answersOk} onClick={handleCreate} variant='contained' sx={{m:"auto", display:"block"}}>Créer la concession</Button>
        </Box>
    )

}


function PopupDetailRenouvellements({open, renouvellements, concessionnaires, callback}){

    const handleAdd = ()=>{

    }

    return(
        <Modal open={open||false} onClose={()=>callback(false)}>
            <Box >
                <Box sx={{m:4}}>
                    <Typography variant="h4" sx={{textAlign:"center", mb:2}}>Renouvellements</Typography>
                    {renouvellements.map((r,i)=>{
                        let c = concessionnaires.find(c=>c.id=r?.id)
                        return(
                            <Box>
                                <Typography variant="h5" sx={{textAlign:"center", mb:2}}>{i===0?"Acquisition":"Renouvellement "+i}</Typography>
                                <Box sx={{ml:4}}>
                                    <Typography><strong>Date : </strong>{r?.acorde_le}</Typography>
                                    <Typography><strong>Durée : </strong>{r?.duree}</Typography>
                                    <Typography><strong>Expire le : </strong>{r?.expirant_le}</Typography>
                                    <Typography><strong>Par : </strong>{prenomNOM(c) + (c?.fondateur && " (fondateur)")}</Typography>
                                </Box>
                            </Box>
                        )
                    })}
                    <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
                        <Button onClick={()=>callback(false)} variant="outlined" color="primary" >Annuler</Button>
                        <Button onClick={handleAdd} variant="contained" color="primary" startIcon={<Add/>}>Ajouter un renouvellement</Button>
                    </Box>
                </Box>
            </Box>
      </Modal>
    )
}


function PopupModifConcession({open, concession, callback}){
    const [newPhoto, setNewPhoto] = useState(false);
    const [reponses, setReponses] = useState({});
    const [answersOk, setAnswersOk] = useState(false);

    useEffect(() => {
        setReponses(concession)
    }, [concession]);

    const handleChange = (key, value)=>{
        setReponses(currVal => ({...currVal, [key]:value}))
    }
    
    const handleChangePhoto = (e)=>{
        const files = Array.from(e.target.files)
        setReponses(currVal => ({...currVal, file:files[0]}))
    }

    useEffect(() => {
        setAnswersOk(Object.values(reponses).filter(a=>a===undefined).length===0)
        //console.log("reponsesLigneCommande",reponses)
    }, [reponses]);

    const handleModify = ()=>{
        if(reponses.type!=="Collective"){
            delete reponses.sepulture_pour
        }
        //reponses["Emplacement_nom"] = concession.Emplacement_nom
        API("PUT", "Concession", reponses, {}, "multipart/form-data")
        .then(res=>{
            console.log(res.json)
        })

        setNewPhoto(false)
        setReponses({})
        setAnswersOk(false)
        callback(true)
    }


    return(
        <Modal open={open||false} onClose={()=>callback(false)}>
            <Box>
                <Box sx={{m:4}}>
                    <Typography variant="h4" sx={{textAlign:"center", mb:2}}>Modification de la concession</Typography>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>N° Concession : </Typography>
                        <Input defaultValue={concession?.numero} required={true} onChange={(v)=>handleChange("numero",v)} style={{width:250}} otherProps={{size:"small"}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Description : </Typography>
                        <Input defaultValue={concession?.description} onChange={(v)=>handleChange("description",v)} style={{width:250}} otherProps={{size:"small",multiline:true}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Photo : </Typography>
                        {concession?.photo === null || newPhoto ?
                            <input type='file' onChange={handleChangePhoto} accept="image/png, image/jpeg, image/gif" />
                        :
                            <Box sx={{position:"relative", width:250}}>
                                <img src={concession?.photo} alt='Photo' width={250}/>
                                <IconButton onClick={()=>setNewPhoto(true)} color='secondary' sx={{position:"absolute", right:0, p:0}}><Cancel/></IconButton>
                            </Box>
                        }
                        {/*<Input onChange={handleChangePhoto} otherProps={{size:"small", type:"file"}}  style={{width:250}}/>
                        {/*<DragAndDropFile typeFichier="image/*" multiple={false} onChange={handleChange} />*/}
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Type : </Typography>
                        <Dropdown options={["Familiale","Collective","Individuelle"]} defaultValue={concession?.type} onChange={(v)=>handleChange("type",v)} style={{width:250}} otherProps={{size:"small"}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Sépulture pour : </Typography>
                        <Input disabled={reponses.type!=="Collective"} onChange={(v)=>handleChange("sepulture_pour",v)} style={{width:250}} otherProps={{size:"small",multiline:true}}
                             defaultValue={reponses.type==="Collective"?concession?.sepulture_pour:
                                           (reponses.type==="Familiale"?"le fondateur et sa famille" : "le fondateur")                 
                            }
                        />
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Commentaires : </Typography>
                        <Input defaultValue={concession?.commentaires} onChange={(v)=>handleChange("commentaire",v)} style={{width:250}} otherProps={{size:"small",multiline:true}}/>
                    </Box>
        
                    <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
                        <Button onClick={()=>callback(false)} variant="outlined" color="primary" >Annuler</Button>
                        <Button disabled={!answersOk} onClick={handleModify} variant="contained" color="primary" >Modifier</Button>
                    </Box>
                </Box>
            </Box>
      </Modal>
    )
}




const styleInputLabel={
    marginY:"auto", 
    display:"inline-block", 
    mr:2,
    minWidth:"150px",
    textAlign:"right",
    fontWeight:"bold",
    fontSize:"17px"
}
const styleBoxInput={
    display:"flex", 
    justifyContent:"center", 
    mb:2
}

