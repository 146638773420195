import React, {useState, useEffect} from 'react';
import { TextField } from '@mui/material';


export default function Input({name, defaultValue, required, InputProps, onChange, disabled, style, otherProps={}}){
    const [value, setValue] = useState(defaultValue)
    const [error, setError] = useState(false)

    useEffect(()=>{
        if(defaultValue==undefined) defaultValue=""
        setValue(defaultValue)
    },[defaultValue])

    const handleChange=(event)=>{
        const v = event.target.value
        setValue(v)
        if(required && v ===""){
            setError(true)
            onChange(undefined)
        }else{
            setError(false)
            onChange(v)
        }
    } 
    return (
        <TextField
            /*id={id}*/
            label={name}
            value={value||""}
            inputProps={InputProps}
            variant="outlined"
            onChange={handleChange}
            //required={required}
            //error={error}
            disabled={disabled}
            style={style}
            {...otherProps}
        />
    )
}




    /*{endAdornment: <InputAdornment position="end">{produit.uniteMesure}</InputAdornment>,
    inputComponent: NumberFormatCustom}*/
