import React, {useEffect, useState} from 'react';
import { MenuItem, TextField, Autocomplete} from '@mui/material';



export default function Dropdown({name, defaultValue, required, options, helperText, onChange, disabled=false, disableOptions=[], addFirstDisabledOption=false, style, inputProps, otherProps={}}){
    const [selected, select] = useState("")
    const [error, setError] = useState(false)
    const [ht, setHt] = useState("")

    useEffect(()=>{
        select(defaultValue||"")
    },[defaultValue])

    useEffect(()=>{
        if(!helperText) return
        setHt(helperText.text)
        setError(helperText.type==="error")
    },[helperText])
    
    /*if(required){
        name=name.concat(" *")
    }*/

    const handleChange= (ev)=>{
        let v=ev.target.value
        select(v)
        if(required && v ===""){
            setError(true)
            onChange(undefined)
        }else{
            setError(false)
            onChange(v)
        }
    }
    
    return(
        <TextField select label={name?name + (required?" *":""):""} value={selected} onChange={handleChange} disabled={disabled} helperText={ht} error={error} sx={{width:"100%",...style}} InputProps={inputProps} {...otherProps}> 
            {addFirstDisabledOption && 
                <MenuItem key={0} value="" disabled></MenuItem>
            }
            {options.length > 0 && options.map((o)=>{
                if(o.hasOwnProperty('id')){
                    return <MenuItem key={o.id} value={o.id} disabled={o.disabled}>{o.name}</MenuItem>
                }else{
                    return <MenuItem key={o} value={o} disabled={disableOptions.includes(o)}>{o}</MenuItem>
                }
                //return <MenuItem key={o} value={o}>{o}</MenuItem>

            })}
        </TextField>
    )

    /*return(
        <TextField select label={name} value={selected} onChange={handleChange} helperText={ht} error={error} style={style} > 
            <MenuItem key={0} value="" disabled></MenuItem>
            {options.length && options.map((o)=>(
                <MenuItem key={o.id} value={o.id} disabled={o.disabled}>{o.name}</MenuItem>
            ))};
        </TextField>
    )*/
}