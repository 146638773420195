import React, { useEffect, useState } from 'react';
import {Typography, Button, IconButton} from '@mui/material';
import API from '../services/API';
import { DataGrid } from '@mui/x-data-grid';
import { ArrowCircleDown, ArrowCircleUp, Info } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

export default function Alertes({}){
    const [alertes, setAlertes] = useState([]);
    
    let navigate = useNavigate();

    const columnActionNonTraitee = [
        {field: 'action', headerName: '', sortable: false, flex:1,
            renderCell: (params) => {
                const onClick = (e)=>{
                    e.stopPropagation();
                    handleChangeStatut(params.row,"En traitement")
                }
                const onClickInfo = (e)=>{
                    e.stopPropagation();
                    navigate("/concession/"+params.row.Concession_numero)
                }
                return(
                    <>
                        <IconButton  onClick={onClick}><ArrowCircleDown/></IconButton>
                        <IconButton  onClick={onClickInfo}><Info/></IconButton>
                    </>
                )
            },
        } 
    ];
    const columnActionEnTraitement = [
        {field: 'action', headerName: '', sortable: false, flex:1,
            renderCell: (params) => {
                const onClick = (e)=>{
                    e.stopPropagation();
                    handleChangeStatut(params.row,"Non traitée")
                }
                const onClickInfo = (e)=>{
                    e.stopPropagation();
                    navigate("/concession/"+params.row.Concession_numero)
                }
                return(
                    <>
                        <IconButton  onClick={onClick}><ArrowCircleUp/></IconButton>
                        <IconButton  onClick={onClickInfo}><Info/></IconButton>
                    </>
                )
            },
        } 
    ];

    useEffect(() => {
        API("GET","Alerte",{},{statut:["Non traitée","En traitement"]})
        .then(res=>setAlertes(res.json))
    }, []);

    const handleChangeStatut = (alerte, statut) =>{
        API("PUT", "Alerte",{...alerte, statut:statut})
        .then(res=>{
            if(res.status===200){
                setAlertes(currVal=>{
                    let tmp = [...currVal]
                    tmp.find(t=>t.id === alerte.id).statut = statut
                    console.log(tmp);
                    return tmp
                })
            }
        })
    }

    return(
        <>
            <Typography variant="h5" sx={{textAlign:"center"}}>Alertes</Typography>
            
            <Typography variant='h6' sx={{mt:4}}>Non traitées</Typography>
            <DataGrid
                columns={[...columnsAlerte, ...columnActionNonTraitee]}
                rows={alertes.filter(a=>a.statut==="Non traitée")}
                autoHeight
                initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                    pagination: { paginationModel: { pageSize: 25 } },
                }}
                hideFooterPagination={alertes.filter(a=>a.statut==="Non traitée").length<=25}
                pageSizeOptions={[25]}
            />

            <Typography variant='h6' sx={{mt:4}}>Traitement en cours</Typography>
            <DataGrid
                columns={[...columnsAlerte, ...columnActionEnTraitement]}
                rows={alertes.filter(a=>a.statut==="En traitement")}
                autoHeight
                initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                    pagination: { paginationModel: { pageSize: 25 } },
                }}
                hideFooterPagination={alertes.filter(a=>a.statut==="En traitement").length<=25}
                pageSizeOptions={[25]}
            />
        </>
    )
}


const columnsAlerte = [
    { field: 'id', headerName: 'ID', flex:1},
    { field: 'Concession_numero', headerName: 'N° Concession', flex:1},
    { field: 'type', headerName: 'Type', flex:1},
    { field: 'date', headerName: 'Date de création', flex:1},
]