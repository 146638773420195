export default function API (method = 'GET', path='', params='', querys={}, type="application/json", init={}, bearer=""){
    var myHeaders = new Headers();
    myHeaders.append("key", "3rXcR23744Hfx9S3NzX43VWgxGynqJ");
    if(bearer!=""){
        console.log('bearer',bearer);
        myHeaders.append("Authorization", `Bearer ${bearer}`);
    }
    var myInit = {
        method: method,
        ...init
    };
    if(method==="POST" || method==="PATCH" || method==="PUT" || method==="DELETE"){
        if(type ==="multipart/form-data"){
            var data = new FormData()
            data.append("file",params.file)
            delete params.file
            for (const [key, value] of Object.entries(params)) {
                data.append(key,JSON.stringify(value))
            }
            myInit={...myInit,headers:myHeaders, body: data}
        }else{
            myHeaders.append("Content-Type", "application/json");
            myInit={...myInit, headers: myHeaders, body : JSON.stringify(params)}   
        }
    }else{
        myInit={...myInit, headers: myHeaders}
    }
    const url = new URL(process.env.REACT_APP_API_URL+"/"+path);
    Object.keys(querys).forEach(key => {
        if(querys[key]){
            if(typeof(querys[key])!="string") querys[key] = JSON.stringify(querys[key])
            url.searchParams.append(key, querys[key])
        }
    })

    console.log(myInit)

    let tmpResponse
    return fetch(url, myInit)
    .then(async response => {
        //console.log("coucou",response)
        tmpResponse = response
        if(response.status === 204){
            tmpResponse.json = []
            return tmpResponse
        }
        tmpResponse.json = await response.json()
        return tmpResponse
    })
    .catch(err => {
        if(err.name === "SyntaxError" && 
            (err.message.includes("nexpected") && err.message.includes("JSON") ||   //U-unexpected JSON (chrome ou firefox)
            err.message.includes("string did not match the expected pattern"))){    // idem (safari)
            return tmpResponse
        }
        throw new Error(err)
    });
}
