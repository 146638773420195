import { DataGrid } from '@mui/x-data-grid';
import {Typography, Button} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import API from '../services/API';

export default function Concessions(){
    const [concessions, setConcessions] = useState([]);
    
    let navigate = useNavigate();

    let columns = [
        { field: 'numero', headerName: 'Numéro', flex:1},
        { field: 'Emplacement_nom', headerName: 'Emplacement', flex:1},
        { field: 'type', headerName: 'Type', flex:1, hide:true},
        { field: 'description', headerName: 'Description', flex:3},
        { field: 'statut', headerName: 'Statut', flex:1, hidden:true},
        {field: 'action', headerName: '', sortable: false, flex:1,
            renderCell: (params) => {
                const onClick = (e)=>{
                    e.stopPropagation();
                    navigate("/concession/"+params.row.numero)
                }
                return  <Button variant='contained' onClick={onClick}>Détails</Button>
            },
        }
    ];


    useEffect(() => {
        API("GET", "Concession")
        .then(res=>{
            setConcessions(res.json) 
        })
    }, []);

    return(
        <>
            <Typography variant="h5" sx={{textAlign:"center"}}>Concessions</Typography>

            <DataGrid
                columns={columns}
                rows={concessions}
                autoHeight
                getRowId={(row) => row.numero}
                initialState={{
                    columns: {
                      columnVisibilityModel: {
                        type: false,
                        statut: false
                      },
                    },
                  }}
            />
        </> 
    )
}