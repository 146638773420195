import {useParams, useNavigate} from "react-router-dom";

export default function Tempo({}){
    let { pageSuivante } = useParams();
    
    let navigate = useNavigate();

    setTimeout(()=>{
        navigate(decodeURIComponent(pageSuivante))
    },1000)

}