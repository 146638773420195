import { useState } from 'react';
import DragAndDropFile from '../components/DragAndDropFile'
import API from '../services/API'
import API2 from '../services/API2'



export default function PopupAjoutFichier ({}){
    const [params, setParams] = useState({numero:"coucou"});

    const handleChange = (e)=>{
        const files = Array.from(e.target.files)
        setParams({Concession_numero:1,nom:"Fichier test",file: files[0]})
    }
    const send = (e)=>{
        API("POST", "fichier",params,{},"multipart/form-data")
        .then(res=> console.log(res))
    }

    return(
        <>
            <input type='file' onChange={handleChange} />
            <button onClick={send}>Send</button>
        </>
    )
    return(
        <>
            <DragAndDropFile typeFichier="image/*" multiple={false} onChange={handleChange} />
        </>
    )
}