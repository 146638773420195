import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Rect, Text, Image, Shape, Line} from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import API from '../services/API';
import { AiOutlineColumnWidth, AiOutlineColumnHeight } from 'react-icons/ai';
import {Typography, Box, Button, ButtonGroup, Modal, Chip, CircularProgress } from '@mui/material';
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import { useNavigate } from "react-router-dom";
import { prenomNOM } from '../services/Utils';
import { useTheme } from '@mui/material/styles';


export default function Plan({}){
    const [image] = useImage('plan_cimetiere2r.png');
    const [emplacements, setEmplacements] = useState([]);
    const [stageScale, setStageScale] = useState(1);
    const [x, setX] = useState(0);
    const ref = useRef(null)
    const [popupOpen, setPopupOpen] = useState(false)
    const [emplacementSelectionne, setEmplacementSelectionne] = useState(undefined);
    const [colombariumOpen, setColombariumOpen] = useState(false);


    useEffect(() => {
        API("GET","Emplacement",{},{occupation:true})
        .then(res=>{
            console.log(res.json);
            setEmplacements(res.json)
        })

        changeSize("widthFit")
    }, []);


    const changeSize = (fit)=>{
        if(fit=="widthFit"){
            setStageScale(800/1035)
            setX(0)
        }else if(fit=="heightFit"){
            let scale = (window.innerHeight -60)/1486
            setStageScale(scale)
            setX((800-(1035*scale))/(2*scale))
        }
    }

    const handleClick = (emplacement)=>{
        setEmplacementSelectionne(emplacements.find(e=>e.nom === emplacement))
        setPopupOpen(true)
    }

    const callbackPopup = (action)=>{
        if(action == false){
            setPopupOpen(false)
        }
    }
    const callbackPopupColombarium = (action)=>{
        if(action === false){
            setColombariumOpen(false)
        }else if(action !== true){
            setEmplacementSelectionne(emplacements.find(e=>e.nom === action))
            setPopupOpen(true)
        }
    }

    return (
        <>
            <div style={{position:"relative"}}>
                <Box sx={{position:"absolute", left: "calc(50% - 210px)", translate: "-50%"}}>
                    <Chip label="Tombe occupée" sx={{bgcolor:"emeraude.light"}}/>
                    <Chip label="Tombe disponible" sx={{bgcolor:"emeraude.main"}}/>
                </Box>
                <Box sx={{position:"absolute", left: "calc(50% + 210px)", translate: "-50%"}}>
                    <Chip label="Cavurne occupée" sx={{bgcolor:"orchide.light"}}/>
                    <Chip label="Cavurne disponible" sx={{bgcolor:"orchide.main"}}/>
                </Box>
                <Typography variant="h5" sx={{textAlign:"center"}}>Plan du cimetière</Typography>
                <ButtonGroup variant="outlined" sx={{position:"absolute", top:0, right:1, "& .MuiButton-startIcon":{mx:0}}}>
                    <Button variant="outlined" startIcon={<AiOutlineColumnWidth />} onClick={()=>changeSize("widthFit")}></Button>
                    <Button variant="outlined" startIcon={<AiOutlineColumnHeight />} onClick={()=>changeSize("heightFit")}></Button>
                </ButtonGroup>
            </div>
            <div style={{width:820, margin:'auto', height:"calc(100vh - 60px)", overflow: "auto"}}  ref={ref}>
                <div>
                <Stage width={800} height={stageScale*1486} scale={{x:stageScale,y:stageScale}}>
                    <Layer x={x}>
                        <Image image={image} />
                    </Layer>
                    <Layer x={x}>
                        <Shape
                            sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(0, 0);
                            context.lineTo(1035, 0);
                            context.lineTo(1035, 273);
                            context.lineTo(1014, 273);
                            context.lineTo(1014, 313);
                            context.lineTo(1035, 313);
                            context.lineTo(1035, 1486);
                            context.lineTo(647, 1486);
                            context.quadraticCurveTo(644, 1425, 585, 1422);
                            context.lineTo(585, 1429);
                            context.lineTo(551, 1429);
                            context.lineTo(551, 1395);
                            context.lineTo(585, 1395);
                            context.lineTo(585, 1402);
                            context.quadraticCurveTo(653, 1412, 663, 1463);
                            context.lineTo(1013, 1463);
                            context.lineTo(1013, 19);
                            context.lineTo(22, 19);
                            context.lineTo(22, 1463);
                            context.lineTo(368, 1463);
                            context.quadraticCurveTo(378, 1411, 449, 1401);
                            context.lineTo(449, 1395);
                            context.lineTo(481, 1395);
                            context.lineTo(481, 1429);
                            context.lineTo(449, 1429);
                            context.lineTo(449, 1422);
                            context.quadraticCurveTo(388, 1419, 385, 1486);
                            context.lineTo(0, 1486);
                            //context.arcTo(369,1457,446,1457,77);
                            context.closePath();
                            // (!) Konva specific method, it is very important
                            context.fillStrokeShape(shape);
                            }}
                            //fill="#00D2FF"
                            stroke="black"
                            strokeWidth={4}
                            fill='gray'
                        />
                    </Layer>
                    <Layer x={x}>
                        {emplacements.filter(e=>e.type!=="Colombarium").map(t=>
                            <Tomb {...t} onClick={handleClick}/> 
                        )}
                        <Line
                            x={0}
                            y={0}
                            points={[22, 1398, 45, 1398, 45, 1441, 75, 1441, 75, 1463, 22, 1463]}
                            closed
                            stroke="black"
                            fill="#aa291d"
                            onClick={()=>setColombariumOpen(true)}
                        />
                        <Text
                            text='Colombarium'
                            x={50}
                            y={1400}
                            fontSize={25}
                            fill="#aa291d"
                            onClick={()=>setColombariumOpen(true)}
                        />
                    </Layer>
                </Stage>
                </div>
            </div>

            <PopupEmplacement open={popupOpen} emplacement={emplacementSelectionne} callbackPopup={callbackPopup} />
            <PopupColombarium open={colombariumOpen} emplacements={emplacements.filter(e=>e.type==="Colombarium")} callbackPopup={callbackPopupColombarium} />
        </>
    );
}

function PopupEmplacement({open, emplacement, callbackPopup}){
    const [innoccupe, setInnoccupe] = useState(false);
    const [concession, setConcession] = useState(undefined);
    const [defunts, setDefunts] = useState([]);
    const [concessionnaire, setConcessionnaire] = useState({});
    const [renouvellement, setRenouvellement] = useState({});
    const [inProgress, setInProgress] = useState(true);

    let navigate = useNavigate();
    

    const stylePopup = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "40%",
        minWidth: "400px",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        maxHeight : "95vh",
        overflow : "auto"
    };

    useEffect(() => {
        if(!open) return

        setInProgress(true)
        let promises=[]

        API("GET", "Concession", {}, {Emplacement_nom:emplacement.nom, enCours:1})
        .then(res=>{
            if(res.status===200){
                let concession = res.json[0]
                setConcession(concession)
                setInnoccupe(false)

                promises.push(
                    API("GET", "Defunt",{},{Concession_numero:concession.numero, OrderBy:"date_inhumation DESC"})
                    .then(res => setDefunts(res.json))
                )
                
                promises.push(
                    API("GET","Concessionnaire/actif",{},{Concession_numero:concession.numero})
                    .then(res=>setConcessionnaire(res.json))
                )
                
                promises.push(
                    API("GET","Renouvellement/dernier",{},{Concession_numero:concession.numero})
                    .then(res=>setRenouvellement(res.json))
                )
                Promise.all(promises).then(allRes=>{
                    setInProgress(false)
                })
            }else if(res.status===204){
                setInnoccupe(true)
                setInProgress(false)
            }
        })
    }, [open]);

    const callback = (action)=>{
        setConcession(undefined)
        setDefunts([])
        callbackPopup(action)
    }

    return(
        <Modal open={open||false} onClose={()=>callback(false)}>
            <Box sx={stylePopup}>
                {inProgress && <Box sx={{margin: 3, textAlign:"center"}}><CircularProgress/></Box>}
                {!inProgress && 
                    <Box sx={{m:4}}>
                        <Typography variant="h4" sx={{textAlign:"center", mb:2}}>Emplacement {emplacement?.nom}</Typography>
                        {innoccupe ?
                        <>
                            <Typography>Cet emplacement est inoccupé.<br/>Souhaitez-vous créer une nouvelle concession ici ?</Typography>
                            <Button onClick={()=>navigate("/concession/creer/"+emplacement.nom)} sx={{display:"block", mx:"auto", mt:2}} variant='contained'>Créer une concession</Button>
                        </>
                        :
                        <>
                            <Typography variant="h5"><strong>Concession : </strong>{concession?.numero}</Typography>
                            <Typography variant="h5"><strong>Description : </strong>{concession?.description}</Typography>
                            <Typography variant="h5"><strong>Défunts : </strong></Typography>
                            <ul style={{marginTop:0}}>
                                {defunts.map(d=>{
                                    let annee_naissance = d?.date_naissance==="?" ? "inconnue" : dayjs(d?.date_naissance).year()
                                    let annee_inhumation = d?.date_inhumation==="?" ? "inconnue" : dayjs(d?.date_inhumation).year()
                                    return(
                                        <li><Typography variant="h6">{prenomNOM(d)}
                                            {/*d?.prenom + " "} 
                                            {d?.nom_usage? d?.nom_usage.toUpperCase() + " (né.e "+d?.nom_naissance?.toUpperCase() +")" : d?.nom_naissance?.toUpperCase()}
                                    {" ("+annee_naissance+"-"+annee_inhumation+")"*/}
                                        </Typography></li>
                                    )
                                })}
                            </ul>
                            <Typography variant="h5"><strong>Concessionnaire : </strong>{concessionnaire.nom_naissance===undefined ? <span style={{color:"red"}}>Non défini</span> : prenomNOM(concessionnaire)}</Typography>
                            <Typography variant="h5"><strong>Type : </strong>{concession?.type}</Typography>
                            <Typography variant="h5"><strong>Expirant le : </strong>{renouvellement?.duree===null? <span style={{color:"red"}}>Renouvellement à contrôler</span> : (renouvellement?.duree==="perpétuel"?"- (perpétuel)" : dayjs(renouvellement?.expirant_le).format("DD-MM-YYYY"))}</Typography>
                            <Button onClick={()=>navigate("/concession/"+concession?.numero)} sx={{display:"block", mx:"auto", mt:2}} variant='contained'>Détails</Button>
                        </>
                        }
                    </Box>
                }
            </Box>
      </Modal>
    )
}

function PopupColombarium({open, emplacements, callbackPopup}){
    const [scale, setScale] = useState(1);

    const [image] = useImage('colombarium.jpg');
    let stylePopup = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width : (1536*scale)+"px", 
        height:"95vh",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        overflow : "none"
    };

    useEffect(() => {
        setScale(window.innerHeight *0.95/1305)
    }, []);

    return(
        <Modal open={open||false} onClose={()=>callbackPopup(false)}>
            <Box sx={stylePopup}>
                <Stage width={1536*scale} height={1305*scale} scale={{x:scale,y:scale}}>
                    <Layer>
                        <Image image={image} />
                    </Layer>
                    <Layer>
                        {emplacements.filter(e=>e.type==="Colombarium").map(t=>
                            <Tomb {...t} onClick={callbackPopup}/>    
                        )}
                    </Layer>
                </Stage>
            </Box>
        </Modal>
    )
}
  
const customComponents = {
    "Rect" : RectTomb,
    "Line" : LineTomb
}
  
function Tomb ({dessin_forme,...other}) {
    if(Object.keys(customComponents).includes(dessin_forme)){
        const CustomComponent = customComponents[dessin_forme]
        return <CustomComponent {...other}/> 
    }
}
  
function RectTomb ({nom, type, dessin_x, dessin_y, dessin_largeur, dessin_hauteur, dessin_texte_offset_x, dessin_texte_offset_y, occupe, onClick}){
    
    const theme = useTheme()
    return (
          <>
              <Rect
                  x={dessin_x}
                  y={dessin_y}
                  width={dessin_largeur}
                  height={dessin_hauteur}
                  stroke="black"
                  onClick={()=>onClick(nom)}
                  fill={type=="Cavurne"?(occupe?theme.palette.orchide.light:theme.palette.orchide.main):(occupe?theme.palette.emeraude.light:theme.palette.emeraude.main)}
              />
              <Text
                  text={nom} 
                  //fontSize={30}
                  fontSize={Math.min(30,dessin_largeur*1.5/nom.length)}
                  x={dessin_x + dessin_texte_offset_x}
                  y={dessin_y + dessin_texte_offset_y}
                  width={dessin_largeur}
                  height={dessin_hauteur}
                  align='center'
                  verticalAlign='middle'
                  onClick={()=>onClick(nom)}
              />
          </>
      )
  }
function LineTomb ({nom, type, dessin_x, dessin_y, dessin_largeur, dessin_hauteur, dessin_texte_offset_x, dessin_texte_offset_y, dessin_data, occupe, onClick}){

    const theme = useTheme()
    return (
          <>
              <Line
                  x={0}
                  y={0}
                  points={JSON.parse(dessin_data).points}
                  closed
                  onClick={()=>onClick(nom)}
                  fill={occupe?"#F0AEA888":"#aa291d88"}
              />
              <Text
                  text={nom} 
                  fontSize={30}
                  //fontSize={Math.min(30,dessin_largeur*1.5/nom.length)}
                  x={dessin_texte_offset_x-50}
                  y={dessin_texte_offset_y}
                  width={100}
                  //height={20}
                  align='center'
                  verticalAlign='middle'
                  onClick={()=>onClick(nom)}
              />
          </>
      )
  }

  
const ColoredRect = () => {
    const [color, setColor] = useState('green');
  
    const handleClick = () => {
      setColor(Konva.Util.getRandomColor());
    };
  
    return (
      <Rect
        x={20}
        y={20}
        width={50}
        height={50}
        fill={color}
        shadowBlur={5}
        onClick={handleClick}
      />
    );
  };

