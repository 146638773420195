import React, { useEffect, useState } from 'react';
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import { DatePicker } from "@mui/x-date-pickers";


export default function DatePickerAbstract({defaultValue, required, onChange, otherProps={} }){
    const [value, setValue] = useState(null)

    useEffect(()=>{
        /*if(defaultValue==="today"){
            setValue(dayjs())
        }*/
        if(defaultValue !== undefined){
            setValue(dayjs(defaultValue))
        }
    },[defaultValue])

    const handleChange = (v)=>{
        setValue(v)
        if(v!=null && v.isValid()){
            onChange(v.format("YYYY-MM-DD"))
        }else if(required){
            onChange(undefined)
        }else{
            onChange("")
        }
    }

    return(
        <DatePicker 
            onChange={handleChange} 
            value={value} 
            sx={{width:250, "& > .MuiInputBase-root > .MuiInputBase-input":{py:1}}} 
            {...otherProps}  //disabled, sx, format="DD-MM-YYYY" 
        />
    )
}