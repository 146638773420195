import dayjs from "dayjs"



export function prenomNOM(personne){
    return personne?.prenom + " " + 
            (personne?.nom_usage? personne?.nom_usage.toUpperCase() + " (né.e "+personne?.nom_naissance?.toUpperCase() +")" : 
            personne?.nom_naissance?.toUpperCase())
}

export function getFormattedDate(dateString,format="all"){
    if(dateString.length === 4 || format==="YYYY"){
        return dayjs(dateString).format("YYYY")
    }else if(dateString.length === 7 || format==="MM-YYYY"){
        return dayjs(dateString).format("MM-YYYY")
    }else if(dateString.length === 10){
        return dayjs(dateString).format("DD-MM-YYYY")
    }
}

export function estFormatDateValide(chaine) {
  const regexYYYY = /^(?:\d{4})$/;
  const regexMMYYYY = /^(?:(?:0[1-9]|1[0-2])-\d{4})$/;
  const regexDDMMYYYY = /^(?:(?:0[1-9]|[12][0-9]|3[01])-(?:0[1-9]|1[0-2])-\d{4})$/;

  return regexYYYY.test(chaine) || regexMMYYYY.test(chaine) || regexDDMMYYYY.test(chaine);
}