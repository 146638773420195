
import React, { useEffect, useMemo, useState } from 'react';
import {useParams, useNavigate} from "react-router-dom";
import {Typography, Box, Button, ButtonGroup, Modal, IconButton, Link, FormControlLabel, Checkbox, Radio, RadioGroup} from '@mui/material';
import {EditOutlined, Add, FileCopyOutlined, FileUpload, Cookie, Cancel, Delete, Edit, Check} from '@mui/icons-material';
import API from '../services/API';
import { getFormattedDate, prenomNOM, estFormatDateValide } from '../services/Utils';
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import { useTheme } from '@mui/material/styles';
import Input from '../components/Input';
import Dropdown from '../components/Dropdown';
import DragAndDropFile from '../components/DragAndDropFile';
import DatePickerAbstract from '../components/DatePickerAbstract';
import {useSnackbar} from 'notistack';



export default function Concession({}){
    const [concession, setConcession] = useState({});
    const [emplacement, setEmplacement] = useState({});
    const [renouvellements, setRenouvellements] = useState([]);
    const [concessionnaires, setConcessionnaires] = useState([]);
    const [fondateur, setFondateur] = useState(undefined);
    const [defunts, setDefunts] = useState([]);
    const [fichiers, setFichiers] = useState([]);

    const [openPopupDetailRenouvellements, setOpenPopupDetailRenouvellements] = useState(false);
    const [openPopupDetailConcessionnaires, setOpenPopupDetailConcessionnaires] = useState(false);
    const [openPopupModifConcession, setOpenPopupModifConcession] = useState(false);
    const [openPopupDefunt, setOpenPopupDefunt] = useState(false);
    const [openPopupAjoutFichier, setOpenPopupAjoutFichier] = useState(false);
    const [openPopupConfSuppFichier, setOpenPopupConfSuppFichier] = useState(false);

    const [defuntSelectionne, setdefuntSelectionne] = useState({});
    const [fichierSelectionne, setFichierSelectionne] = useState({});

    const [refreshData, setRefreshData] = useState(false);

    let { numero } = useParams();
    const theme = useTheme();

    useEffect(() => {
        API("GET", "Concession", {}, {numero:numero})
        .then(res=>{
            let concession = res.json[0]
            setConcession(concession)

            API("GET", "Emplacement",{},{nom:concession.Emplacement_nom})
            .then(res=> setEmplacement(res.json[0]))
        }) 

        API("GET", "Renouvellement", {},{Concession_numero:numero})
        .then(res=>setRenouvellements(res.json))

        API("GET", "Concessionnaire", {},{Concession_numero:numero})
        .then(res=>{
            setConcessionnaires(res.json)
            setFondateur(res.json.find(c=>c.fondateur===1))
        })

        API("GET", "Defunt", {},{Concession_numero:numero, OrderBy:"date_naissance ASC"})
        .then(res=>setDefunts(res.json))

        API("GET", "Fichier", {},{Concession_numero:numero})
        .then(res=>setFichiers(res.json))

    }, [numero]);

    useEffect(() => {
        if(!refreshData) return
        
        if(refreshData==="Concession"){
            API("GET", "Concession", {}, {numero:numero})
            .then(res=>setConcession(res.json[0])) 
        }else if(refreshData==="Renouvellement"){
            API("GET", "Renouvellement", {}, {Concession_numero:numero})
            .then(res=>setRenouvellements(res.json)) 
        }else if(refreshData==="Concessionnaire"){
            API("GET", "Concessionnaire", {}, {Concession_numero:numero})
            .then(res=>setConcessionnaires(res.json)) 
        }else if(refreshData==="Defunt"){
            API("GET", "Defunt", {},{Concession_numero:numero, OrderBy:"date_naissance ASC"})
            .then(res=>setDefunts(res.json))
        }else if(refreshData==="Fichier"){
            API("GET", "Fichier", {},{Concession_numero:numero})
            .then(res=>setFichiers(res.json))
        }


        setRefreshData(false)
    }, [refreshData]);

    const callbackPopupDetailRenouvellements = (ret)=>{
        setOpenPopupDetailRenouvellements(false)
        if(ret){
            setRefreshData("Renouvellement")
        }
    }
    const callbackPopupDetailConcessionnaires = (ret)=>{
        setOpenPopupDetailConcessionnaires(false)
        if(ret){
            setRefreshData("Concessionnaire")
        }
    }
    const callbackPopupModifConcession = (ret)=>{
        setOpenPopupModifConcession(false)
        if(ret){
            setRefreshData("Concession")
        }
    }

    const handleModifDefunt = (defunt)=>{
        setdefuntSelectionne(defunt)
        setOpenPopupDefunt("Modification")
    }
    const callbackPopupDefunt = (ret)=>{
        setOpenPopupDefunt(false)
        if(ret){
            setRefreshData("Defunt")
        }
    }

    const callbackPopupAjoutFichier = (ret)=>{
        setOpenPopupAjoutFichier(false)
        if(ret){
            setRefreshData("Fichier")
        }
    }

    const handleSupprFichier = (f)=>{
        setFichierSelectionne(f)
        setOpenPopupConfSuppFichier(true)
    }
    const callbackPopupConfSupprFichier = (ret)=>{
        setOpenPopupConfSuppFichier(false)
        if(ret){
            setRefreshData("Fichier")
        }
    }

    /*var width = Math.floor(Math.random() * (11)) * 100 + 200;
    var height = Math.floor(Math.random() * (11)) * 100 + 200;
    let src = concession?.photo || "https://picsum.photos/800/1000"*/
    //let src = "https://picsum.photos/800/1000"
    let src = concession?.photo || "/photos_concessions/Sans_photo.png"
    

    useEffect(() => {
        console.log("concessionnaires",concessionnaires);
    }, [concessionnaires]);
    useEffect(() => {
        console.log("fondateur",fondateur);
    }, [fondateur]);

    let dernierRenouvellement = renouvellements[renouvellements.length-1]
    let dernierConcessionnaire = concessionnaires[concessionnaires.length-1]


    return(
        <Box sx={{mb:2}}>
            <Box sx={{display:"flex"}}>
                <Box sx={{height:"200px", width:"40%", maxHeight:"300px", maxWidth:"600px",
                        backgroundImage: 'url("'+src+'")',
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"}}>
                </Box>
                <Box sx={{margin: 3, flexGrow: 1}}>
                    <Typography variant='h4' sx={{textAlign:"center"}}><strong>Emplacement {emplacement.nom}</strong></Typography>
                    <Box sx={{display:"flex", fontSize:"12px", justifyContent: "space-around", flexWrap: "wrap", width: "400px", margin: "auto"}}>
                        <span style={infosEmplStyle}><strong>Type : </strong>{emplacement.type}</span>
                        {emplacement.numero_caveau!==null && <span style={infosEmplStyle}><strong>N° caveau : </strong>{emplacement.numero_caveau}</span>}
                        {emplacement.superficie!==null && <span style={infosEmplStyle}><strong>Superficie : </strong>{emplacement.superficie} m²</span>}
                        {emplacement.nombre_places!==null && <span style={infosEmplStyle}><strong>Nombre de places : </strong>{emplacement.nombre_places}</span>}
                    </Box>

                    <Typography variant='h5' sx={{mt:2,textAlign:"center"}}><strong>N° Concession : </strong>{numero}</Typography>
                    {concession.description!==null && 
                        <Typography variant='h5' sx={{textAlign:"center"}}><strong>Description : </strong>{concession.description}</Typography>
                    }
                </Box>
            </Box>

            <Typography variant='h5' sx={{mt:2}}><strong>Fondateur : </strong>{fondateur ? prenomNOM(fondateur) : <span style={{color:"red"}}>inconnu</span>}</Typography>
            <Typography variant='h5' sx={{mt:2}}><strong>Type : </strong>{concession.type}</Typography>
            <Typography variant='h5' sx={{mt:2}}><strong>Sépulture pour : </strong>
                {concession.type === "Familiale" && (fondateur?.sexe==="F" ? "elle-même" : "lui-même") + " et sa famille"}
                {concession.type === "Individuelle" && (fondateur?.sexe==="F" ? "elle-même" : "lui-même")}
                {concession.type === "Collective" && concession.sepulture_pour}
            </Typography>
            <Typography variant='h5' sx={{mt:2}}>
                <strong>Expirant le : </strong>
                {dernierRenouvellement && (dernierRenouvellement?.duree === "perpétuel" ? "- (perpétuel)" : dayjs(dernierRenouvellement?.expirant_le).format("DD-MM-YYYY"))}
                <Link component="button" onClick={() => setOpenPopupDetailRenouvellements(true)} underline="hover" sx={{fontSize:"18px", color:"blue", ml:3}}>Détail des renouvellements</Link>
            </Typography>
            <Typography variant='h5' sx={{mt:2}}>
                <strong>Concessionnaire : </strong>
                {dernierConcessionnaire && prenomNOM(dernierConcessionnaire)}
                <Link component="button" onClick={() => setOpenPopupDetailConcessionnaires(true)} underline="hover" sx={{fontSize:"18px", color:"blue", ml:3}}>Détail des concessionnaires</Link>
            </Typography>
            <Box sx={{display:"flex", alignItems: "baseline"}}>
                <Typography variant='h5' sx={{mt:2, flex: "0 0 180px"}}><strong>Commentaires : </strong></Typography>
                <Typography sx={{display:"inline-block"}}>{concession.commentaire}</Typography>
            </Box>
            <Button onClick={()=>setOpenPopupModifConcession(true)} variant='contained' startIcon={<EditOutlined/>} sx={{display:"flex",margin:"auto"}}>Modifier concession</Button>

            <Box sx={{border:"dashed 1px black", mt:3, p:2}}>
                <Typography variant='h5' sx={{display:"inline-block"}}><strong>Défunts</strong></Typography>
                <Button onClick={()=>setOpenPopupDefunt("Ajout")} variant="contained" startIcon={<Add/>} sx={{float:"inline-end"}}>Ajouter</Button>
                <table style={{width:"100%", padding:"10px"}}>
                    <thead>
                        <tr style={{backgroundColor:theme.palette.primary.main, color:"white"}}>
                            <th style={{textAlign:"left", width:"40%", paddingLeft:"10px"}}>Nom</th>
                            <th style={{width:"15%"}}>Naissance</th>
                            <th style={{width:"15%"}}>Inhumation</th>
                            <th style={{width:"15%"}}>Exhumation</th>
                            <th style={{width:"15%"}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {defunts.map((d,i)=>(
                            <tr key={d.id}  style={{backgroundColor:i%2?theme.palette.primary.lighter : theme.palette.background.main}}>
                                <td style={{width:"40%", paddingLeft:"10px"}}>{prenomNOM(d)}</td>
                                <td style={{width:"15%", textAlign:"center"}}>{d?.date_naissance}</td>
                                <td style={{width:"15%", textAlign:"center"}}>{d?.date_inhumation}</td>
                                <td style={{width:"15%", textAlign:"center"}}>{d?.date_exhumation||""}</td>
                                <td style={{width:"15%", textAlign:"center"}}>
                                    <IconButton onClick={()=>handleModifDefunt(d)}><EditOutlined/></IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>

            <Typography variant='h5' sx={{mt:2, flex: "0 0 180px"}}><strong>Fichiers : </strong></Typography>
            <Box sx={{display:"flex"}}>
                {fichiers.map(f=>(
                    <Box key={f.id} sx={{display: "flex", flexDirection: "column", alignItems: "center", position:"relative"}}>
                        <IconButton onClick={()=>window.open(f.url, '_blank')} color="primary" sx={{pb:0}}><FileCopyOutlined sx={{fontSize:"80px"}}/></IconButton>
                        <IconButton onClick={()=>handleSupprFichier(f)} color='secondary' sx={{position:"absolute", right:0, p:0}}><Cancel/></IconButton>
                        <span>{f.nom}</span>
                    </Box>
                ))}
                {fichiers.length>0 && 
                    <Box sx={{borderLeft:"dashed 4px gray"}}></Box>
                }
                <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <IconButton onClick={()=>setOpenPopupAjoutFichier(true)} color="primary" sx={{pb:0, /*bgcolor:"primary.main", color:"white"*/}}><FileUpload sx={{fontSize:"80px"}}/></IconButton>
                    Ajouter
                </Box>
            </Box>

            <PopupDetailRenouvellements open={openPopupDetailRenouvellements} Concession_numero={numero} renouvellements={renouvellements} concessionnaires={concessionnaires} callback={callbackPopupDetailRenouvellements} />
            <PopupDetailConcessionnaires open={openPopupDetailConcessionnaires} Concession_numero={numero} concessionnaires={concessionnaires} callback={callbackPopupDetailConcessionnaires} />
            <PopupModifConcession open={openPopupModifConcession} concession={concession} callback={callbackPopupModifConcession}/>
            <PopupDefunt open={openPopupDefunt} Concession_numero={concession?.numero} defunt={defuntSelectionne} concessionnaires={concessionnaires} callback={callbackPopupDefunt} />
            <PopupAjoutFichier open={openPopupAjoutFichier} Concession_numero={concession?.numero} callback={callbackPopupAjoutFichier}/>
            <PopupConfSupprFichier open={openPopupConfSuppFichier} fichier={fichierSelectionne} callback={callbackPopupConfSupprFichier}/>
        </Box>
    )

}
var infosEmplStyle = {
    flex: "0 0 calc(50% - 10px)",
    margin: "5px",
    textAlign: "center"
}


function PopupDetailRenouvellements({open, Concession_numero, renouvellements, concessionnaires, callback}){
    const [openPopupAjoutRenouvellement, setOpenPopupAjoutRenouvellement] = useState(false);

    const handleAdd = ()=>{
        setOpenPopupAjoutRenouvellement(true)
    }

    const callbackAjout = (etat)=>{
        setOpenPopupAjoutRenouvellement(false)
        if(etat){
            callback(true)
        }
    }

    return(
        <>
        <Modal open={open||false} onClose={()=>callback(false)}>
            <Box sx={stylePopup}>
                <Box sx={{m:4}}>
                    <Typography variant="h4" sx={{textAlign:"center", mb:2}}>Renouvellements</Typography>
                    {renouvellements.map((r,i)=>{
                        let c = concessionnaires.find(c=>c.id===r?.Concessionnaire_id)
                        return(
                            <Box key={r.id}>
                                <Typography variant="h5" sx={{textDecoration:"underline"}}>{i===0?"Acquisition":"Renouvellement "+i}</Typography>
                                <Box sx={{ml:4}}>
                                    <Typography><strong>Date : </strong>{dayjs(r?.accorde_le).format("DD-MM-YYYY")}</Typography>
                                    <Typography><strong>Durée : </strong>{r?.duree}</Typography>
                                    <Typography><strong>Expire le : </strong>{r?.expirant_le===null ? "-" : dayjs(r?.expirant_le).format("DD-MM-YYYY")}</Typography>
                                    <Typography><strong>Par : </strong>{prenomNOM(c) + (c?.fondateur && " (fondateur)")}</Typography>
                                </Box>
                            </Box>
                        )
                    })}
                    <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
                        <Button onClick={()=>callback(false)} variant="outlined" color="primary" >Annuler</Button>
                        <Button onClick={handleAdd} variant="contained" color="primary" startIcon={<Add/>}>Ajouter un renouvellement</Button>
                    </Box>
                </Box>
            </Box>
      </Modal>
      <PopupAjoutRenouvellement open={openPopupAjoutRenouvellement} Concession_numero={Concession_numero} dernierConcessionnaire={concessionnaires[concessionnaires.length-1]} callback={callbackAjout} />
      </>
    )
}

function PopupAjoutRenouvellement({open, Concession_numero, dernierConcessionnaire, callback}){
    const [reponses, setReponses] = useState({accorde_le:dayjs().format("YYYY-MM-DD"), duree:undefined, statut:"payé"});
    const [expirantLe, setExpirantLe] = useState(["",null]);
    const [confCochee, setConfCochee] = useState(false);
    const [answersOk, setAnswersOk] = useState(false);

    useEffect(() => {
        setReponses({accorde_le:dayjs().format("YYYY-MM-DD"), duree:undefined, statut:"payé"})
        setConfCochee(false)
    }, [open]);

    const handleChange = (key, value)=>{
        setReponses(currVal => ({...currVal, [key]:value}))
    }

    useEffect(() => {
        let duree = parseInt(reponses.duree, 10)
        if(reponses.accorde_le === undefined || isNaN(duree)){
            setExpirantLe(["-",null])
        }else{
            let tmp = dayjs(reponses.accorde_le).add(duree,"year")
            setExpirantLe([tmp.format("DD-MM-YYYY"), tmp.format("YYYY-MM-DD")])
        }
    }, [reponses]);

    useEffect(() => {
        setAnswersOk(confCochee && Object.values(reponses).filter(a=>a===undefined).length===0)
    }, [reponses, confCochee]);

    const label = useMemo(()=>{
        return "Je confirme que ce renouvellement est effectué par le concessionnaire actuel, "+prenomNOM(dernierConcessionnaire) +", toujours en vie. (sinon, merci de modifier le concessionnaire d’abord)"
    },[dernierConcessionnaire])

    const handleAdd = ()=>{
        let toSend = reponses
        reponses.Concession_numero = Concession_numero
        reponses.expirant_le = expirantLe[1]
        reponses.Concessionnaire_id = dernierConcessionnaire.id
        API("POST","Renouvellement",toSend)
        .then(res=>{
            if(res.status !== 201){
                console.log(res)
                alert("Erreur lors de l'ajout du renouvellement")
                callback(false)
            }else{
                callback(true)
            }
        })
    }

    return(
        <Modal open={open||false} onClose={()=>callback(false)}>
            <Box sx={stylePopup}>
                <Box sx={{m:4}}>
                    <Typography variant="h4" sx={{textAlign:"center", mb:2}}>Ajout renouvellement</Typography>
                    
                    
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Accordé le : </Typography>
                        <DatePickerAbstract required={true} onChange={(v)=>handleChange("accorde_le",v)} defaultValue={"today"} format="DD-MM-YYYY" sx={{width:250, "& > .MuiInputBase-root > .MuiInputBase-input":{py:1}}} />
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Durée : </Typography>
                        <Dropdown required={true} options={["5 ans","10 ans","15 ans","30 ans","50 ans","perpétuel"]} onChange={(v)=>handleChange("duree",v)} style={{width:250}} otherProps={{size:"small"}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Expirant le : </Typography>
                        <Input disabled={true} defaultValue={expirantLe[0]} style={{width:250}} otherProps={{size:"small"}} />
                    </Box>

                    <FormControlLabel onChange={(e)=>setConfCochee(e.target.checked)} checked={confCochee} control={<Checkbox />} sx={{textAlign: "justify", color: "red", "& .MuiSvgIcon-root":{color:"red"}}}
                        label={label} 
                    />

                    
                    <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
                        <Button onClick={()=>callback(false)} variant="outlined" color="primary" >Annuler</Button>
                        <Button disabled={!answersOk} onClick={handleAdd} variant="contained" color="primary" startIcon={<Add/>}>Ajouter un renouvellement</Button>
                    </Box>
                </Box>
            </Box>
      </Modal>
    )
}

function PopupDetailConcessionnaires({open, Concession_numero, concessionnaires, callback}){
    const [openPopupAjoutConcessionnaire, setOpenPopupAjoutConcessionnaire] = useState(false);
    const [data, setData] = useState({});

    const handleModif = (concessionnaire)=>{
        setData(concessionnaire)
        setOpenPopupAjoutConcessionnaire("Modification")
    }

    const handleAdd = ()=>{
        setOpenPopupAjoutConcessionnaire("Ajout")
    }

    const callbackAjout = (etat)=>{
        setOpenPopupAjoutConcessionnaire(false)
        if(etat){
            callback(true)
        }
    }

    return(
        <>
        <Modal open={open||false} onClose={()=>callback(false)}>
            <Box sx={stylePopup}>
                <Box sx={{m:4}}>
                    <Typography variant="h4" sx={{textAlign:"center", mb:2}}>Concessionnnaires</Typography>
                    {concessionnaires.map((c,i)=>(
                        <Box key={c.id}>
                            <Typography variant="h5" sx={{textDecoration:"underline", display:"inline-block"}}>{prenomNOM(c) + (c.fondateur===1?" (fondateur)":"")}</Typography>
                            <IconButton onClick={()=>handleModif(c)} variant="contained" color='primary' sx={{display:"inline-block"}}><Edit/></IconButton>
                            <Box sx={{ml:4}}>
                                <Typography><strong>Concessionnaire depuis : </strong>{dayjs(c?.depuis_le).format("DD-MM-YYYY")}</Typography>
                                <Typography><strong>Date de naissance : </strong>{dayjs(c?.date_naissance).format("DD-MM-YYYY")}{c?.decede===1 && (c?.sexe==="M"?" (décédé)":" (décédée)")}</Typography>
                                <Typography><strong>Téléphone : </strong>{c?.telephone}</Typography>
                                <Typography><strong>Email : </strong>{c?.email}</Typography>
                                <Typography><strong>Adresse : </strong>{c?.adresse}</Typography>
                                <Typography><strong>Commentaire : </strong>{c?.commentaire}</Typography>
                            </Box>
                        </Box>
                    ))}
                    <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
                        <Button onClick={()=>callback(false)} variant="outlined" color="primary" >Annuler</Button>
                        <Button onClick={handleAdd} variant="contained" color="primary" startIcon={<Add/>}>Ajouter un concessionnaire</Button>
                    </Box>
                </Box>
            </Box>
      </Modal>
      <PopupAjoutModifConcessionnaire open={openPopupAjoutConcessionnaire} Concession_numero={Concession_numero} concessionnaires={concessionnaires} data={data} callback={callbackAjout} />
      </>
    )
}
function PopupAjoutModifConcessionnaire({open, Concession_numero, concessionnaires, data, callback}){
    const [reponses, setReponses] = useState({depuis_le: dayjs().format("YYYY-MM-DD"), prenom:undefined, nom_naissance:undefined, fondateur:0, /*date_naissance:undefined,*/ sexe:undefined});
    const [confCochee, setConfCochee] = useState(false);
    const [answersOk, setAnswersOk] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(open==="Modification"){
            setReponses(data)
        }else{
            setReponses({depuis_le: dayjs().format("YYYY-MM-DD"), prenom:undefined, nom_naissance:undefined, fondateur:0, /*date_naissance:undefined,*/ sexe:undefined})
            setConfCochee(false)
        }
    }, [open]);

    const handleChange = (key, value)=>{
        setReponses(currVal => ({...currVal, [key]:value}))
    }


    useEffect(() => {
        setAnswersOk((confCochee || open==="Modification") && Object.values(reponses).filter(a=>a===undefined).length===0)
    }, [reponses, confCochee]);

    const label = useMemo(()=>{
        return "Je confirme que cette personne est en vie et est un héritier du fondateur, "+prenomNOM(concessionnaires.find(c=>c.fondateur===1))
    },[concessionnaires])

    const handleValidate = ()=>{
        if(open==="Ajout"){
            let toSend = reponses
            reponses.Concession_numero = Concession_numero
            API("POST","Concessionnaire",toSend)
            .then(res=>{
                if(res.status !== 201){
                    console.log(res)
                    alert("Erreur lors de l'ajout du concessionnaire")
                    callback(false)
                }else{
                    callback(true)
                }
            })

        }else if(open==="Modification"){ //Modification
            API("PUT", "Concessionnaire", reponses)
            .then(res=>{
                if(res.status === 200){
                    callback(true)
                }else if (res.status === 460){
                    enqueueSnackbar(res.json.messageFr,{variant: "error"})
                }else{
                    console.log(res)
                    alert("Erreur lors de la modification du concessionnaire")
                    callback(false)
                }
            })
        }
    }

    return(
        <Modal open={open||false} onClose={()=>callback(false)}>
            <Box sx={{...stylePopup, width:"60%"}}>
                <Box sx={{m:4}}>
                    <Typography variant="h4" sx={{textAlign:"center", mb:2}}>{open} concessionnaire</Typography>
                    
                    <Box sx={{display:"flex"}}>
                        <Box sx={{width:"50%"}}>
                            <Box sx={styleBoxInput}>
                                <Typography sx={styleInputLabel}>Prénom *</Typography>
                                <Input required={true} defaultValue={reponses?.prenom} onChange={(v)=>handleChange("prenom",v)} style={{width:250}} otherProps={{size:"small"}}/>
                            </Box>
                            <Box sx={styleBoxInput}>
                                <Typography sx={styleInputLabel}>Nom de naissance *</Typography>
                                <Input required={true} defaultValue={reponses?.nom_naissance} onChange={(v)=>handleChange("nom_naissance",v)} style={{width:250}} otherProps={{size:"small"}}/>
                            </Box>
                            <Box sx={styleBoxInput}>
                                <Typography sx={styleInputLabel}>Nom d'usage</Typography>
                                <Input defaultValue={reponses?.nom_usage} onChange={(v)=>handleChange("nom_usage",v)} style={{width:250}} otherProps={{size:"small"}}/>
                            </Box>
                            {open==="Modification" &&
                            <>
                                <Box sx={styleBoxInput}>
                                    <Typography sx={styleInputLabel}>Fondateur</Typography>
                                    <Box sx={{width:"250px", textAlign:"center"}}><Checkbox onChange={(e)=>handleChange("fondateur", e.target.checked?1:0)} checked={reponses?.fondateur===1} /></Box>
                                </Box>
                                <Box sx={styleBoxInput}>
                                    <Typography sx={styleInputLabel}>Concessionnaire depuis</Typography>
                                    <DatePickerAbstract defaultValue={reponses?.depuis_le} required={true} onChange={(v)=>handleChange("depuis_le",v)} format="DD-MM-YYYY" sx={{width:250, "& > .MuiInputBase-root > .MuiInputBase-input":{py:1}}} />
                                </Box>
                            </>
                            }
                            <Box sx={styleBoxInput}>
                                <Typography sx={styleInputLabel}>Sexe *</Typography>
                                <RadioGroup value={reponses?.sexe||""} onChange={(e)=>handleChange("sexe",e.target.value)} sx={{flexDirection:"row", width:"250px", justifyContent:"center"}}>
                                    <FormControlLabel value="M" control={<Radio />} label="M" />
                                    <FormControlLabel value="F" control={<Radio />} label="F" />
                                </RadioGroup>
                            </Box>
                        </Box>
                        <Box sx={{width:"50%"}}>
                            <Box sx={styleBoxInput}>
                                <Typography sx={styleInputLabel}>Date de naissance</Typography>
                                <DatePickerAbstract defaultValue={reponses?.date_naissance} /*required={true} */onChange={(v)=>handleChange("date_naissance",v)} format="DD-MM-YYYY" sx={{width:250, "& > .MuiInputBase-root > .MuiInputBase-input":{py:1}}} />
                            </Box>
                            {open==="Modification" &&
                            <Box sx={styleBoxInput}>
                                <Typography sx={styleInputLabel}>{reponses?.sexe==="M"?"Décédé":"Décédée"}</Typography>
                                <Box sx={{width:"250px", textAlign:"center"}}><Checkbox onChange={(e)=>handleChange("decede", e.target.checked?1:0)} checked={reponses?.decede===1} /></Box>
                            </Box>
                            }
                            <Box sx={styleBoxInput}>
                                <Typography sx={styleInputLabel}>Téléphone</Typography>
                                <Input defaultValue={reponses?.telephone} onChange={(v)=>handleChange("telephone",v)} style={{width:250}} otherProps={{size:"small"}}/>
                            </Box>
                            <Box sx={styleBoxInput}>
                                <Typography sx={styleInputLabel}>Email</Typography>
                                <Input defaultValue={reponses?.email} onChange={(v)=>handleChange("email",v)} style={{width:250}} otherProps={{size:"small"}}/>
                            </Box>
                            <Box sx={styleBoxInput}>
                                <Typography sx={styleInputLabel}>Adresse</Typography>
                                <Input defaultValue={reponses?.adresse} onChange={(v)=>handleChange("adresse",v)} style={{width:250}} otherProps={{size:"small",multiline:true, rows:2}}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Commentaire</Typography>
                        <Input defaultValue={reponses?.commentaire} onChange={(v)=>handleChange("commentaire",v)} style={{width:"80%"}} otherProps={{size:"small",multiline:true}}/>
                    </Box>

                    {open === "Ajout" &&
                        <FormControlLabel onChange={(e)=>setConfCochee(e.target.checked)} checked={confCochee} control={<Checkbox />} sx={{display: "flex", justifyContent: "center", textAlign: "center", color: "red", "& .MuiSvgIcon-root":{color:"red"}}}
                            label={label} 
                        />
                    }

                    
                    <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
                        <Button onClick={()=>callback(false)} variant="outlined" color="primary" >Annuler</Button>
                        <Button disabled={!answersOk} onClick={handleValidate} variant="contained" color="primary" startIcon={open==="Ajout"?<Add/>:<Check/>}>{open==="Ajout"?"Ajouter un":"Modifier le"} concessionnaire</Button>
                    </Box>
                </Box>
            </Box>
      </Modal>
    )
}

function PopupModifConcession({open, concession, callback}){
    const [newPhoto, setNewPhoto] = useState(false);
    const [reponses, setReponses] = useState({});
    const [answersOk, setAnswersOk] = useState(false);

    let navigate = useNavigate();

    /*useEffect(() => {
        setReponses(concession)
    }, [concession]);*/
    useEffect(() => {
        console.log("reprep", reponses);
    }, [reponses]);

    useEffect(() => {
        if(open){
            setReponses(concession)
        }else{
            setReponses({})
        }
    }, [open]);

    const handleChange = (key, value)=>{
        setReponses(currVal => ({...currVal, [key]:value}))
    }
    
    const handleChangePhoto = (e)=>{
        const files = Array.from(e.target.files)
        setReponses(currVal => ({...currVal, file:files[0]}))
    }

    useEffect(() => {
        setAnswersOk(Object.values(reponses).filter(a=>a===undefined).length===0)
        //console.log("reponsesLigneCommande",reponses)
    }, [reponses]);

    const handleModify = ()=>{
        if(reponses.type!=="Collective"){
            delete reponses.sepulture_pour
        }
        //reponses["Emplacement_nom"] = concession.Emplacement_nom
        let tmp_nouveau_numero = reponses.nouveau_numero
        API("PUT", "Concession", reponses, {}, "multipart/form-data")
        .then(res=>{
            console.log(res.json)
            if(tmp_nouveau_numero){
                let url = "/tempo/"+encodeURIComponent("/concession/"+tmp_nouveau_numero)
                console.log(url);
                navigate(url)
            }
        })

        setNewPhoto(false)
        setAnswersOk(false)
        callback(true)
    }


    return(
        <Modal open={open||false} onClose={()=>callback(false)}>
            <Box sx={stylePopup}>
                <Box sx={{m:4}}>
                    <Typography variant="h4" sx={{textAlign:"center", mb:2}}>Modification de la concession</Typography>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>N° Concession : </Typography>
                        <Input defaultValue={concession?.numero} required={true} onChange={(v)=>handleChange("nouveau_numero",v)} style={{width:250}} otherProps={{size:"small"}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Description : </Typography>
                        <Input defaultValue={concession?.description} onChange={(v)=>handleChange("description",v)} style={{width:250}} otherProps={{size:"small",multiline:true}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Photo : </Typography>
                        {concession?.photo === null || newPhoto ?
                            <input type='file' onChange={handleChangePhoto} accept="image/png, image/jpeg, image/gif" />
                        :
                            <Box sx={{position:"relative", width:250}}>
                                <img src={concession?.photo} alt='Photo' width={250}/>
                                <IconButton onClick={()=>setNewPhoto(true)} color='secondary' sx={{position:"absolute", right:0, p:0}}><Cancel/></IconButton>
                            </Box>
                        }
                        {/*<Input onChange={handleChangePhoto} otherProps={{size:"small", type:"file"}}  style={{width:250}}/>
                        {/*<DragAndDropFile typeFichier="image/*" multiple={false} onChange={handleChange} />*/}
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Type : </Typography>
                        <Dropdown options={["Familiale","Collective","Individuelle"]} defaultValue={concession?.type} onChange={(v)=>handleChange("type",v)} style={{width:250}} otherProps={{size:"small"}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Sépulture pour : </Typography>
                        <Input disabled={reponses.type!=="Collective"} onChange={(v)=>handleChange("sepulture_pour",v)} style={{width:250}} otherProps={{size:"small",multiline:true}}
                             defaultValue={reponses.type==="Collective"?concession?.sepulture_pour:
                                           (reponses.type==="Familiale"?"le fondateur et sa famille" : "le fondateur")                 
                            }
                        />
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Commentaires : </Typography>
                        <Input defaultValue={concession?.commentaire} onChange={(v)=>handleChange("commentaire",v)} style={{width:250}} otherProps={{size:"small",multiline:true}}/>
                    </Box>
        
                    <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
                        <Button onClick={()=>callback(false)} variant="outlined" color="primary" >Annuler</Button>
                        <Button disabled={!answersOk} onClick={handleModify} variant="contained" color="primary" >Modifier</Button>
                    </Box>
                </Box>
            </Box>
      </Modal>
    )
}

function PopupDefunt({open, Concession_numero, defunt, concessionnaires, callback}){
    const [confCochee, setConfCochee] = useState(false);
    const [reponses, setReponses] = useState({});
    const [answersOk, setAnswersOk] = useState(false);

    const label = useMemo(()=>{
        return "Je confirme que le concessionnaire actuel, "+prenomNOM(concessionnaires[concessionnaires.length-1])+", est toujours en vie et reste le concessionnaire de cette concession (sinon, merci d'ajouter le nouveau concessionnaire d'abord)"
    },[concessionnaires])

    useEffect(() => {
        if(open==="Modification"){
            setReponses(defunt)
        }else{
            setReponses({Concession_numero:Concession_numero, prenom:undefined, nom_naissance:undefined, date_naissance:undefined, date_inhumation:undefined})
        }
    }, [open]);

    const handleChange = (key, value)=>{
        setReponses(currVal => ({...currVal, [key]:value}))
    }

    useEffect(() => {
        const notNull = Object.values(reponses).filter(a=>a===undefined).length===0
        const datesValides = estFormatDateValide(reponses.date_naissance) && estFormatDateValide(reponses.date_inhumation) && (["",undefined,null].includes(reponses.date_exhumation) || estFormatDateValide(reponses.date_exhumation))
        setAnswersOk(notNull && datesValides && (confCochee||open==="Modification"))
        console.log("reponses",reponses)
    }, [reponses, confCochee]);

    const handleValidate = ()=>{
        let method = open==="Ajout"?"POST":"PUT"
        API(method, "Defunt", reponses)
        .then(res=>{
            console.log(res.json)
            callback(true)
        })

    }

    const handleDelete = ()=>{
        API("DELETE", "Defunt", {id:defunt.id})
        .then(res=>{
            callback(true)
        })
    }


    return(
        <Modal open={open||false} onClose={()=>callback(false)}>
            <Box sx={stylePopup}>
                <Box sx={{m:4}}>
                    <Typography variant="h4" sx={{textAlign:"center", mb:2}}>{open} d'un défunt</Typography>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Prénom : </Typography>
                        <Input required={true} defaultValue={open==="Modification"?defunt?.prenom:""} onChange={(v)=>handleChange("prenom",v)} style={{width:250}} otherProps={{size:"small"}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Nom de naissance : </Typography>
                        <Input required={true} defaultValue={open==="Modification"?defunt?.nom_naissance:""} onChange={(v)=>handleChange("nom_naissance",v)} style={{width:250}} otherProps={{size:"small"}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Nom d'usage : </Typography>
                        <Input defaultValue={open==="Modification"?defunt?.nom_usage:""} onChange={(v)=>handleChange("nom_usage",v)} style={{width:250}} otherProps={{size:"small"}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Date de naissance : </Typography>
                        <Input required={true} defaultValue={open==="Modification"?defunt?.date_naissance:""} onChange={(v)=>handleChange("date_naissance",v)} style={{width:250}} otherProps={{size:"small", helperText:"Formats : JJ-MM-AAAA ou MM-AAAA ou AAAA"}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Date d'inhumation : </Typography>
                        <Input required={true} defaultValue={open==="Modification"?defunt?.date_inhumation:""} onChange={(v)=>handleChange("date_inhumation",v)} style={{width:250}} otherProps={{size:"small"}}/>
                    </Box>
                    {open==="Ajout" &&
                        <FormControlLabel onChange={(e)=>setConfCochee(e.target.checked)} checked={confCochee} control={<Checkbox />} sx={{display: "flex", justifyContent: "center", textAlign: "center", color: "red", "& .MuiSvgIcon-root":{color:"red"}}}
                            label={label} 
                        />
                    }
                    {open==="Modification" &&
                        <>
                            <Box sx={styleBoxInput}>
                                <Typography sx={styleInputLabel}>Date d'exhumation : </Typography>
                                <Input defaultValue={defunt?.date_exhumation} onChange={(v)=>handleChange("date_exhumation",v)} style={{width:250}} otherProps={{size:"small"}}/>
                            </Box>
                            <Box sx={styleBoxInput}>
                                <Typography sx={styleInputLabel}>Commentaire<br/>d'exhumation : </Typography>
                                <Input defaultValue={defunt?.commentaire_exhumation} onChange={(v)=>handleChange("commentaire_exhumation",v)} style={{width:250}} otherProps={{size:"small", multiline:true, rows:2}}/>
                            </Box>
                        </>
                    }
                    
                    <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
                        {open==="Modification" && <Button onClick={handleDelete} variant="outlined" color="secondary" startIcon={<Delete/>}>Supprimer</Button>}
                        <Button onClick={()=>callback(false)} variant="outlined" color="primary" >Annuler</Button>
                        <Button disabled={!answersOk} onClick={handleValidate} variant="contained" color="primary" >{open==="Ajout"?"Ajouter":"Modifier"}</Button>
                    </Box>
                </Box>
            </Box>
      </Modal>
    )
}

function PopupAjoutFichier({open, Concession_numero, callback}){
    const [reponses, setReponses] = useState({});
    const [answersOk, setAnswersOk] = useState(false);

    useEffect(() => {
        setReponses(currVal => ({...currVal, Concession_numero:Concession_numero}))
    }, [Concession_numero]);

    const handleChange = (key, value)=>{
        setReponses(currVal => ({...currVal, [key]:value}))
    }

    const handleChangeFichier = (e)=>{
        const files = Array.from(e.target.files)
        setReponses(currVal => ({...currVal, file:files[0]}))
    }

    useEffect(() => {
        console.log(reponses);
        setAnswersOk(Object.values(reponses).filter(a=>a===undefined).length===0)
    }, [reponses]);

    const handleAdd = ()=>{
        API("POST", "Fichier", reponses, {}, "multipart/form-data")
        .then(res=>{
            setReponses(currVal => ({Concession_numero:currVal.Concession_numero}))
            setAnswersOk(false)
            callback(true)
        })


    }


    return(
        <Modal open={open||false} onClose={()=>callback(false)}>
            <Box sx={stylePopup}>
                <Box sx={{m:4}}>
                    <Typography variant="h4" sx={{textAlign:"center", mb:2}}>Ajout d'un fichier</Typography>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Titre : </Typography>
                        <Input required={true} onChange={(v)=>handleChange("nom",v)} style={{width:250}} otherProps={{size:"small"}}/>
                    </Box>
                    <Box sx={styleBoxInput}>
                        <Typography sx={styleInputLabel}>Fichier : </Typography>
                        <input type='file' onChange={handleChangeFichier} accept='image/*,.pdf'/>

                    </Box>
                    <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
                        <Button onClick={()=>callback(false)} variant="outlined" color="primary" >Annuler</Button>
                        <Button disabled={!answersOk} onClick={handleAdd} variant="contained" color="primary" >Ajouter</Button>
                    </Box>
                </Box>
            </Box>
      </Modal>
    )
}

function PopupConfSupprFichier({open, fichier,  callback}){

    const handleDelete = ()=>{
        API("DELETE", "Fichier", fichier)
        .then(res=>{
            callback(true)
        })

    }


    return(
        <Modal open={open||false} onClose={()=>callback(false)}>
            <Box sx={stylePopup}>
                <Box sx={{m:4}}>
                    <Typography variant="h4" sx={{textAlign:"center", mb:2}}>Confirmation de suppression</Typography>
                    <Typography sx={{textAlign:"center", mb:2}}>Etes vous sûr de vouloir supprimer le fichier "{fichier.nom}" ?</Typography>
                    
                    <Box sx={{display:"flex", justifyContent:"space-evenly"}}>
                        <Button onClick={()=>callback(false)} variant="outlined" color="secondary" >Annuler</Button>
                        <Button onClick={handleDelete} variant="contained" color="secondary" >Supprimer</Button>
                    </Box>
                </Box>
            </Box>
      </Modal>
    )
}


const stylePopup = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    minWidth: "500px",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    maxHeight : "95vh",
    overflow : "auto"
};
const styleInputLabel={
    marginY:"auto", 
    display:"inline-block", 
    mr:2,
    minWidth:"150px",
    textAlign:"right"
}
const styleBoxInput={
    display:"flex", 
    justifyContent:"center", 
    mb:2
}

